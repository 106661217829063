<template>
  <html>
    <body class="" style="background: #FFF">
      <Header />
        <MenuLateralMobile />
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
           <MenuLateral/>
            <div class="col-span-6" style="background:#FFF;padding:20px;">
              <RequestsContributionPaymentNew/>
              <Footer/>
            </div>    
        </div>
    </body>
  </html>
</template>

<script>
import Header from "@/components/Administrative/Header/Header.vue";
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';
import MenuLateral from '@/components/LateralMenu.vue';
import Footer from '@/components/Analyst/Footer.vue';
import RequestsContributionPaymentNew from "@/components/Administrative/Payments/Contribution/RequestsContributionPaymentNew.vue";
import {getTokenDecoden} from "@/helpers/tokenauth";

export default {
  data() {
      return {
          errors: [],
          apiToken: null,
          apiCabsaCore: process.env.VUE_APP_APICABSACORE
        };
      },
  name: 'SolicitudesPagosInscripcionPrincipal',
  components: {
    Header,
    MenuLateralMobile,
    RequestsContributionPaymentNew,
    MenuLateral,
    Footer
  },
   beforeMount() {
    this.checkLogin();
  },
  methods:{
    checkLogin(){
      var user = getTokenDecoden();
      if(user != null){
        if(user.obj['role'] == "member"){
          // window.location.href = "/cuenta/principal"
          this.$router.push({path:"/cuenta/principal"});
        } else if (user.obj['role'] == "administrator"){
          // window.location.href = "/admin/principal"
          this.$router.push({path:"/admin/principal"});
        }
      }
      else{
        // window.location.href = "/"
        this.$router.push({path:"/"});
      }
    }
  }
}
</script>

<style scoped>
.center {
  padding: 00px 0;
  border: 3px solid #ffffff;
  text-align: center;
}
@media (min-width: 766px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}
#button_popups{
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #FEB72B;
  width: 150px;
  height: 30px;
  padding-top: 0%;
}
button {outline: none !important;}
</style>